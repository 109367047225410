// default

<template>
  	<v-container fluid pa-0>
	</v-container>
</template>
<script>
export default {
	name: 'default',
	components: {
	},
	data: () => ({
	}),
	computed: {
	},
	methods: {
	},
};
</script>
<style>
</style>